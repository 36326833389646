import axios from "axios";

//axios.defaults.baseURL = "http://192.168.0.18:5000";
//axios.defaults.baseURL = "http://localhost:32930";
//axios.defaults.baseURL = "http://localhost:5000";
//axios.defaults.baseURL = "https://cdlm.caatvalencia.es:443";
//axios.defaults.baseURL = "http://cdlm2.caatvalencia.es";
axios.defaults.baseURL = "https://api.ciadelamoda.com";


axios.interceptors.request.use(function(config) {
  //config.headers["Access-Control-Allow-Origin"] = "http://cdlm.caatvalencia.es";
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axios;
